<template>
  <!-- 针对诺亚方舟二期，在知情同意书之后新加签署劳务协议页面 -->
  <div class="service_agreement_page">
    <div id="demo"></div>
    <div class="btn">
        <div class="agree-box">
            <van-checkbox v-model="checked" :disabled="checkDisabled" icon-size="16px">我已阅读并同意该知情同意书</van-checkbox>
        </div>
        <van-button round type="info" size="large" class="up" @click="next">点击签名</van-button>
    </div>
    <div class="goBack" @click="back">
        <img src="/static/goBack.png" />
    </div>
    <!-- <van-dialog  title="温馨提示" confirm-button-color="#4B99FF" :message="tipsText"></van-dialog> -->
  </div>
</template>

<script>
import { mapState } from "vuex"
import {
    getSubProjectAgreement,
    projectDoctor,
    subschemeAgre,
    schemeAgre
} from '@/api/api'
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css"
import {
  appHost
} from '@/utils/host'
import {parseUrl} from '@/utils/utils'
import Vue from 'vue';
    import {
        Toast, Uploader,Dialog
    } from "vant";
export default {
  data() {
    return {
      baseFileUrl: appHost,
      pdfh5: null,
      service_agreement: '', // 医生劳务协议
      short_name: '',
      checkDisabled: true,
      checked: false,
      project_doctor_id: this.$route.query.project_doctor_id,
      doctorExamineInfo:{}
    }
  },
  computed: {
    ...mapState(['userId','basicInformation'])
  },
  mounted() {
    this.short_name = this.basicInformation.projects[0].short_name
    this.init()
  },
  methods: {
    async init() {
        
      // 获取劳务协议
      let {
          items
      } = await getSubProjectAgreement()

      this.doctorExamineInfo = await projectDoctor()
      console.log(this.doctorExamineInfo)

      if(this.doctorExamineInfo.service_agreement_reason&&this.doctorExamineInfo.resign_status==3){
        Dialog.alert({
            title: "驳回原因",
            message: this.doctorExamineInfo.service_agreement_reason,
            confirmButtonColor: "#4B99FF",
        }).then(() => {
            
        })
      }
      
      if (this.short_name == 'bxzleq') {
        this.pdfh5 = new Pdfh5("#demo", {
            pdfurl: parseUrl(items[0].resign_service_agreement, this.baseFileUrl)
        });
      } else {
        this.pdfh5 = new Pdfh5("#demo", {
            pdfurl: parseUrl(items[0].service_agreement, this.baseFileUrl)
        });
      }
      //监听完成事件
      this.pdfh5.on("complete", function (status, msg, time) {
          console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
      })
      this.checkDisabled = false
      // res = await getSubProjectAgreement({
      //     params: {
      //         // id: this.$route.query.subscheme_id,
      //         doctor_id: this.userId
      //     }
      // })
      // console.log("获取服务协议：",res)
    },
    createPdf(val) {
      this.$nextTick(()=>{
          this.pdfh5 = new Pdfh5("#demo", {
              pdfurl: parseUrl(val, this.baseFileUrl)
          });
          //监听完成事件
          this.pdfh5.on("complete", function (status, msg, time) {
              console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
          })
      })
    },
    next() {
        if(!this.checked){
            Toast({
            message: "请选择同意该知情同意书",
            duration: 1000
            });
            return
        }
      this.$router.replace({
          path: '/re_sign_service_agreement',
          query: {
            ...this.$route.query
          }
      })
    },
    back() {
      this.$router.replace({
        path:"/sub_project",
        query:{
          ...this.$route.query
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.service_agreement_page{
  height: 100%;
  width: 100%;
  .agree-box {
    line-height: 20px;
    padding: 10px;
    text-align: center !important;
    vertical-align: middle;
      font-size: 14px;
    .van-checkbox {
      justify-content: center;
    }
  }
}
</style>